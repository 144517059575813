import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Avatar,
  Link,
  IconButton,
} from '@mui/material';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import {Box} from '@mui/system';

function GetImageLink(image) {
  const baseUrl = 'https://storage.googleapis.com/find-me-sales-bucket';
  if (process.env.NODE_ENV === 'production') {
    return `${baseUrl}/${image}.webp`;
  }
  return `${baseUrl}/${image}.jpg`;
}

const moment = require('moment');

export default function ShareWall() {
  const posts = [
    {
      avatar:
        'https://media.licdn.com/dms/image/v2/D4E0BAQFT1kG8wsXlhQ/company-logo_200_200/company-logo_200_200/0/1723987440747/leadgenmastery_logo?e=1746057600&v=beta&t=Lymz_-sz1Yi-1MOaCA5OxsRcwqkV_oBFu0MvEm3CiFU',
      author: 'Lead Generation Mastery...',
      handle: '7,509 followers',
      content: 'Exciting news for UK businesses! Growth is promising.',
      image: null,
      date: '2025-01-26T12:00:00Z',
      link: 'https://www.linkedin.com/feed/update/urn:li:activity:7288103400758501378/',
    },
    {
      avatar:
        'https://media.licdn.com/dms/image/v2/D4E0BAQHKvoOwIKRy_w/company-logo_200_200/company-logo_200_200/0/1695046638432?e=1746057600&v=beta&t=Uo2oBUqu7JmgFExY2gfiCTEr3b4ATSRCQhsP6Q9ZY7Q',
      author: 'Innovators Uncensored',
      handle: '1,435 followers',
      content: 'Gaaaame changing Sales tool for Founders!',
      image: null,
      date: '2025-01-24T12:00:00Z',
      link: 'https://www.linkedin.com/feed/update/urn:li:activity:7288494264936747009/',
    },
    {
      avatar: 'https://pbs.twimg.com/profile_images/1854545937919586314/rTlrGs20_400x400.jpg',
      author: 'Martin The M',
      handle: '@Saas_Dude',
      content:
        "@FindMeSalesHQ, loving the progress updates - can't wait to see what january brings.",
      image: null,
      date: '2024-12-31T12:00:00Z',
      link: 'https://x.com/Saas_Dude/status/1874004075194573176',
    },
    {
      avatar: 'https://pbs.twimg.com/profile_images/1871166189155610624/nlpUJOF2_400x400.jpg',
      author: 'Omar',
      handle: '@adsbest4',
      content: 'Looks like a clever strategy. Valuable connections matter.',
      image: null,
      date: '2024-11-08T12:00:00Z',
      link: 'https://x.com/adsbest4/status/1854858344281890928',
    },
  ];
  return (
    <Container style={{minWidth: '100%', padding: '2rem 14px', backgroundColor: '#fafafa'}}>
      <Grid container spacing={2}>
        {posts.map((post, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Link
              key={index}
              link
              href={post.link}
              target="_blank"
              color="primary"
              style={{textDecoration: 'none'}}>
              <Card
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  position: 'relative',
                  height: '100%',
                }}>
                {/* Icon in the top-right corner */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    zIndex: 1,
                  }}>
                  <IconButton
                    size="24px"
                    color="primary"
                    href={post.platform === 'linkedin' ? post.link : undefined}
                    target="_blank"
                    rel="noopener"
                    aria-label={post.platform === 'linkedin' ? 'LinkedIn' : 'Close'}>
                    {post.link.includes('linkedin') ? (
                      <LinkedInIcon style={{fontSize: '30px', color: '#0072b1'}} />
                    ) : (
                      <XIcon />
                    )}
                  </IconButton>
                </Box>

                <CardContent>
                  <Grid container alignItems="center" spacing={2} style={{marginBottom: '1rem'}}>
                    <Grid item>
                      <Avatar src={post.avatar} alt={post.author} style={{width: 48, height: 48}} />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        style={{fontWeight: 'bold', letterSpacing: '0.4px', fontSize: '14px'}}>
                        {post.author}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{fontSize: '0.8rem'}}>
                        {post.handle}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography variant="body1" color="textPrimary">
                    {post.content}
                  </Typography>
                </CardContent>
                {post.image && (
                  <CardMedia
                    component="img"
                    image={GetImageLink(post.image)}
                    alt="Attached media"
                    style={{maxHeight: 200, objectFit: 'cover'}}
                  />
                )}
                <CardContent>
                  {moment(post.date).format('MMM DD, YYYY')}
                  {/* <Link
                  href={post.link}
                  target="_blank"
                  rel="noopener"
                  style={{ fontWeight: 'bold', color: '#1976d2' }}
                  underline="hover"
                >
                  View Post
                </Link> */}
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
